<template>
  <b-card
    class="financeresource-edit-wrapper"
  >
    <!-- form -->
    <b-form id="financeresourceForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="对账日期"
            label-for="verify_date"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="verify_date"
              size="sm"
              v-model="financeresource.verify_date"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="付款单位/对方单位 名称"
            label-for="pay_company_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="pay_company_name"
              size="sm"
              v-model="financeresource.pay_company_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="收款单位ID"
            label-for="company_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="company_id"
              size="sm"
              v-model="financeresource.company_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="收款单位名称"
            label-for="company_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="company_name"
              size="sm"
              v-model="financeresource.company_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="银行账户Id bank_account.account_id"
            label-for="account_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="account_id"
              size="sm"
              v-model="financeresource.account_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="银行账号/收款人账号"
            label-for="account_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="account_no"
              size="sm"
              v-model="financeresource.account_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="店铺团队ID"
            label-for="store_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="store_id"
              size="sm"
              v-model="financeresource.store_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="店铺团队名称"
            label-for="store_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="store_name"
              size="sm"
              v-model="financeresource.store_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="交易日期"
            label-for="trade_date"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="trade_date"
              size="sm"
              v-model="financeresource.trade_date"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="收款方式 statement_pay_method"
            label-for="receive_method"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="receive_method"
              size="sm"
              v-model="financeresource.receive_method"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="收款金额"
            label-for="receive_amount"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="receive_amount"
              size="sm"
              v-model="financeresource.receive_amount"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="退款金额"
            label-for="refund_amount"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="refund_amount"
              size="sm"
              v-model="financeresource.refund_amount"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="认领状态  declare_status"
            label-for="status"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="status"
              size="sm"
              v-model="financeresource.status"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="摘要"
            label-for="remark"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="remark"
              size="sm"
              v-model="financeresource.remark"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="交易流水号"
            label-for="trade_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="trade_no"
              size="sm"
              v-model="financeresource.trade_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="状态"
            label-for="state"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="state"
              size="sm"
              v-model="financeresource.state"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="创建时间"
            label-for="add_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="add_time"
              size="sm"
              v-model="financeresource.add_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="创建人"
            label-for="creator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="creator"
              size="sm"
              v-model="financeresource.creator"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改时间"
            label-for="modify_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="modify_time"
              size="sm"
              v-model="financeresource.modify_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改人"
            label-for="updator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="updator"
              size="sm"
              v-model="financeresource.updator"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import financeresourceStore from './financeresourceStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      financeresource: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('financeresource')) store.registerModule('financeresource', financeresourceStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('financeresource')) store.unregisterModule('financeresource')
    })

    const edit = function() {
      store.dispatch('financeresource/edit', {id: this.id}).then(res => {
        this.financeresource = res.data.data
      })
    }

    const view = function() {
      store.dispatch('financeresource/view', {id: this.id}).then(res => {
        this.financeresource = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('financeresource/save', this.financeresource).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
